<template>
  <div class="container">
    <div
      class="flex justify-between mt-12 items-start md:items-center md:flex-col md:space-y-4 container"
    >
      <div class="flex flex-wrap w-full">
        <a
          href="#"
          class="text-sm py-2 border-primary mr-4 text-gray hover:opacity-75"
          :class="{
            'border-b-2 font-bold  text-primary': !$route.query.status,
          }"
          @click.prevent="$router.push({ query: { status: null } })"
          >所有筆記</a
        ><a
          href="#"
          class="text-sm py-2 border-primary mr-4 text-gray hover:opacity-75"
          :class="{
            'border-b-2 font-bold text-primary': $route.query.status == 1,
          }"
          @click.prevent="$router.push({ query: { status: 1 } })"
          >已購買筆記
        </a>
        <div class="ml-auto">
          <el-input
            v-model="form.keyword"
            placeholder="搜尋筆記"
            clearable
            @clear="fetcher"
            @keyup.enter="search"
          >
            <template #append>
              <el-button :icon="Search" type="primary" @click="search" />
            </template>
          </el-input>
        </div>
      </div>
    </div>
    <div
      v-loading="loading"
      class="mt-8 mb-12 grid grid-cols-4 gap-8 pl-4 md:grid-cols-1"
    >
      <note
        v-for="row in rows"
        :key="row.id"
        :row="row"
        @open:comment="openComment(row.id)"
      ></note>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';
import note from '@/modules/note/_Card.vue';
import useNotes from '@/modules/note/useNotes';
import { ElInput, ElIcon } from 'element-plus';
import { Search } from '@element-plus/icons';
const { rows, fetcher, loading, form, search } = useNotes();
fetcher();
const commentDialog = inject('commentDialog');
const openComment = (id) => {
  commentDialog.id = id;
  commentDialog.toggle({ show: true });
};
</script>
