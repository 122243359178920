import { computed, reactive } from 'vue';
import Note from '@/api/Note';
import { useRoute, useRouter } from 'vue-router';
export default () => {
  const _rows = reactive([]);

  const route = useRoute();
  const router = useRouter();
  const form = reactive({
    keyword: null,
  });

  const rows = computed(() => {
    return route.query.status
      ? _rows.filter((x) => {
          return x.member_status == route.query.status;
        })
      : _rows;
  });
  const fetcher = () => {
    _rows.length = 0;
    return Note.list().then((res) => {
      if (res.code === 1) {
        _rows.push(...res.data.rows);
      }
    });
  };
  const search = () => {
    if (!form.keyword) return;
    _rows.length = 0;
    router.push({ query: { status: null } });
    return Note.search({ ...form }).then((res) => {
      if (res.code === 1) {
        _rows.push(...res.data.rows);
      }
    });
  };
  return { rows, fetcher, form, search };
};
