<template>
  <div class="flex">
    <Router-View v-slot="{ Component }">
      <Component :is="Component" />
    </Router-View>
    <El-Dialog
      v-model="commentDialog.state.show"
      :top="'2em'"
      title="我的註解"
      lock-scroll
    >
      <comment :id="commentDialog.id"></comment>
    </El-Dialog>
  </div>
</template>

<script setup>
import { provide } from 'vue';
import useDialog from '@/hook/useDialog';
import { ElDialog } from 'element-plus';
import comment from '@/modules/note/Comment.vue';
const dialog = useDialog();
const commentDialog = {
  ...dialog,
  id: '',
};
provide('commentDialog', commentDialog);
</script>
