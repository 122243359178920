import Axios from './http';
const prefix = '/consumer/v1';
function modifyKeys(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(modifyKeys);
  }

  const modifiedObj = {};

  for (const key in obj) {
    if (key === 'id') {
      modifiedObj['id'] = `${modifyKeys(obj['id'])}_${modifyKeys(obj['pg'])}`;
    } else if (key === 'chapter' || key === 'section') {
      modifiedObj['children'] = modifyKeys(obj[key]);
    } else {
      modifiedObj[key] = modifyKeys(obj[key]);
    }
  }

  return modifiedObj;
}

export default class Note {
  static async list(params) {
    return Axios(prefix + '/subject/notes', {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
  static async search(params) {
    return Axios(prefix + '/subject/notes/search', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async catalog(params) {
    return Axios(prefix + `/subject/notes/${params.noteId}/catalog`, {
      method: 'get',
      responseType: 'json',
      params,
    }).then((res) => {
      if (res.code === 1) {
        res.data.rows = modifyKeys(res.data.rows);
      }
      return res;
    });
  }

  static async content(params) {
    return Axios(prefix + `/subject/notes/${params.noteId}/content`, {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async token(params) {
    return Axios(prefix + `/subject/notes/preview/token`, {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async preview(params) {
    return Axios(prefix + `/subject/notes/preview/${params.noteId}/content`, {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
  static async previewCatalog(params) {
    return Axios(prefix + `/subject/notes/preview/${params.noteId}/catalog`, {
      method: 'get',
      responseType: 'json',
      params,
    }).then((res) => {
      if (res.code === 1) {
        res.data.rows = modifyKeys(res.data.rows);
      }
      return res;
    });
  }

  static async updateComment(params) {
    return Axios(prefix + `/subject/notes/comment`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async comment(params) {
    return Axios(prefix + `/subject/notes/${params.noteId}/comment`, {
      method: 'get',
      responseType: 'json',
      params,
    });
  }

  static async coverInfo(params) {
    return Axios(prefix + `/subject/notes/preview/${params.noteId}/coverInfo`, {
      method: 'get',
      responseType: 'json',
      params,
    });
  }
}
