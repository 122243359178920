<template>
  <div v-if="row" class="bg-white rounded-lg shadow-lg pl-4 note relative">
    <div class="side"></div>
    <div class="flex flex-col p-6 h-full">
      <div
        v-soft-disabled="!preview && !permission?.check(row.member_status)"
        class=""
      >
        <div class="p-2 bg-primary-light border">
          <ElTooltip
            :hide-after="100"
            :offset="0"
            effect="customized"
            :disabled="!row.published && !preview"
          >
            <template #content>
              <div v-html="addBreaks(row.title)"></div
            ></template>
            <div class="line-clamp-2 text-primary">
              {{ row.title }}
            </div>
          </ElTooltip>
        </div>
        <div class="flex flex-col space-y-1 mt-3 text-xs">
          <div class="flex">
            <div class="label">筆記編號：</div>

            <div class="content line-clamp-1">{{ row.id }}</div>
          </div>
          <div class="flex">
            <div class="label">筆記科目：</div>
            <div class="content line-clamp-1">
              {{ row.subject }}
            </div>
          </div>
          <div class="flex">
            <div class="label">考試種類：</div>
            <ElTooltip
              :hide-after="100"
              :offset="0"
              effect="customized"
              :disabled="!row.published && !preview"
            >
              <template #content>
                <div v-html="addBreaks(row.emCategory)"></div
              ></template>
              <div class="content line-clamp-2">{{ row.emCategory }}</div>
            </ElTooltip>
          </div>
          <div class="flex">
            <div class="label">考試單位：</div>
            <ElTooltip
              :hide-after="100"
              :offset="0"
              effect="customized"
              :disabled="!row.published && !preview"
            >
              <template #content>
                <div v-html="addBreaks(row.emTitle)"></div
              ></template>
              <div class="content line-clamp-2">
                {{ row.emTitle }}
              </div>
            </ElTooltip>
          </div>

          <div class="flex">
            <div class="label">總頁數：</div>
            <div class="content">{{ row.pg || 0 }}</div>
          </div>
        </div>
        <ElTooltip
          :hide-after="100"
          :offset="0"
          effect="customized"
          :disabled="!row.published && !preview"
        >
          <template #content>
            <div v-html="addBreaks(row.desc)"></div
          ></template>
          <div class="text-gray text-sm leading-relaxed line-clamp-4 mt-3">
            {{ row.desc }}
          </div>
        </ElTooltip>

        <div class="text-sm mt-4">
          最後更新 : <span class="text-primary">{{ row.updTime }}</span>
        </div>
      </div>
      <div class="mt-auto flex justify-center pt-4">
        <ElButton
          type="primary"
          :disabled="!row.published && !preview"
          @click="toShow(row)"
        >
          查看筆記
        </ElButton>
        <ElButton
          type="text"
          class=""
          :disabled="!row.published && !preview"
          @click="toComment(row)"
        >
          查看註解
        </ElButton>
      </div>
      <div
        v-if="!row.published && !preview"
        class="absolute w-full h-full flex justify-center items-center left-0 top-0 bg-opacity-50 bg-white"
      >
        <span class="font-bold text-lg">筆記更新中</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, inject, defineEmits } from 'vue';
import { ElTooltip } from 'element-plus';
import { useRouter } from 'vue-router';
const permission = inject('permission');
const router = useRouter();
const auth = inject('auth');

const props = defineProps({
  row: {
    type: Object,
    default: () => {},
  },
  preview: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

const toShow = async (row) => {
  router.push({
    name: 'Note.Show',
    params: { id: row.id },
    query: { pg: 1 },
  });
};
const emits = defineEmits(['open:comment']);

const { commit } = inject('store');
const toComment = async (row) => {
  if (!auth.state.isAuth) {
    commit('state', { status: 'isLogining' });
    return;
  }
  if (!(await permission.dialog(row.member_status))) return;
  emits('open:comment', row.id);
};

const addBreaks = (text, num = 20) => {
  let result = '';
  for (let i = 0; i < text.length; i++) {
    result += text[i];
    if ((i + 1) % num === 0) {
      result += '<br>';
    }
  }
  return result;
};
</script>

<style lang="sass">
.el-popper.is-customized
  padding: 6px 12px
  background: #ccc
  pointer-events: none
.el-popper.is-customized .el-popper__arrow::before
  background: #ccc
//   right: 0
</style>
<style lang="sass" scoped>
.label
  @apply font-bold mr-1 flex-shrink-0

.side
    width: 30px
    height: 93%
    position: absolute
    top: 4%
    left: 0
    transform: translate(-38%, 0)
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAYAAACaq43EAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIfSURBVHgB7VM7bhpRFL0Dw0d85DEFBSBlQEKCCtJRIIwQC3ABVDRZQZwVOFlBlBXYNU2yAJTgFhobIUo8QQJRTqDhJybnTt7gCYHIiV1ZHOno3vdm7u+89yT6EwqYBTOgKkmSytYwDN5XsD6BDyMZbLGnC2rC3oJ3wmp0AJLNPwffioKnIugb+MOWVDvQKFMVluNfC5//b4EfdmMlMdVXdM/FrsEvomOdnoBqtXo1mUyKg8FAHY1GFA6HryuVimYvfC+6esfFFMDv96sej+fM5XKpbrdblWX5FdanWCvwFewRfFqv17RYLLTlcqnD1+Hr8/n8drVa3RUKhc8+n88sMp1OqdFoUD6fp1QqZe7JQhI9EAhkvV7vJRJmHQ6HKRNU4IY0KNISCugorOM7Yc+kdfaw6mazUZxOZxFNXDSbTbO5UChEyWSSuFluYDsxklwg4CMCCFNp+IEnb+kAPQH1et0YDofU6XQIKnBuqtVqFAwGt4XvUbQFeXh9LhRo0cOttM770OXai0gkYozHY4pGoxSLxajX61Eul3uQmuUBOSlP+gY8A4v060lZ1oIKfn9EXcpkMlQul80JcfbU7/d/k1oWBa9EgU9i2pudPKzCyWOLMhKJBM1mM2q329Ttds0G0un09rtkm+SS/i61Rv/wxOxSx+NxUwE7pD0xdqlV+k+pS6WSwWdrXaZdyHv2bugZpMYles8Wz4yOOOKIl4mff7zUZu/RQdEAAAAASUVORK5CYII=")
</style>
