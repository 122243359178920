<template>
  <div v-loading="loading" class="flex flex-col">
    <!-- <div
      class="min-h-[400px] flex-grow ql-editor"
      v-if="reading"
      v-html="row"
    ></div> -->
    <QuillEditor
      v-model:content="row"
      class="flex-grow h-0"
      theme="snow"
      toolbar="minimal"
      content-type="html"
      :option="{
        placeholder: '請輸入文字',
        formats: [
          'background',
          'bold',
          'color',
          'font',
          'code',
          'italic',
          'link',
          'size',
          'strike',
          'script',
          'underline',
          'blockquote',
          'header',
          'indent',
          'list',
          'align',
          'direction',
          'code-block',
          'formula',
          //'image',
          // 'video'
        ],
      }"
      @paste="onPaste"
      @drop="onPaste"
    />

    <div class="flex-none pt-8 flex justify-center">
      <!-- <el-button v-if="reading" class="px-8" type="primary" @click="toggle"
        >編輯模式</el-button
      > -->
      <el-button type="primary" class="px-20" @click="submit"
        >確認修改</el-button
      >
    </div>
  </div>
</template>

<script setup>
import Note from '@/api/Note';
import { ref, defineProps, watch, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import { useDebounceFn } from '@vueuse/core';
const props = defineProps({
  id: { type: String, default: '' },
  reading: { type: Boolean, default: true },
});

const row = ref('');
const reading = ref(props.reading);
const loading = ref(false);
watch(
  () => props.reading,
  (val) => {
    reading.value = val;
  },
);
let editor;
onMounted(() => {
  editor = document.querySelector('.ql-editor');
});
const removeImg = useDebounceFn(() => {
  var images = editor.getElementsByTagName('img');
  var l = images.length;
  for (var i = 0; i < l; i++) {
    images[0].parentNode.removeChild(images[0]);
  }
}, 400);

const onPaste = (evt) => {
  // console.log(evt.clipboardData.items);
  removeImg();
};

const fetcher = () => {
  loading.value = true;
  Note.comment({ noteId: props.id }).then((res) => {
    if (res.code === 1) {
      row.value = res.data.comment;
    } else {
      // 一定要有空格
      row.value = ' ';
    }

    loading.value = false;
  });
};
watch(
  () => props.id,
  (val) => {
    if (!val) return;
    fetcher();
  },
  {
    immediate: true,
  },
);

const toggle = () => {
  reading.value = !reading.value;
};

const submit = () => {
  Note.updateComment({ noteId: props.id, comment: row.value }).then((res) => {
    if (res.code === 1) {
      ElMessage({ message: '內容建立成功' });
    } else {
      ElMessage({ type: 'error', message: '發生問題' });
    }
  });
};
</script>

<style lang="scss" scoped>
:deep(.ql-container) {
  @apply h-[74vh] overflow-auto;
}
</style>
